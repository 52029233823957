
import { inject, reactive, watch, ref } from "vue";
import { Database } from "vuebase";
import { CartItem, Parcel, Product, ShippingMethod } from "../main";
import OrderController from "@/controllers/order-controller";
import { useRoute, useRouter } from "vue-router";
import { ImageData } from "coho-ui";

export default {
    name: "Order",
    setup() {
        const route = useRoute();
        const router = useRouter();
        const orderId = (route.params.id as string) || undefined;
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();
        const orderController = reactive(new OrderController(orderId));
        const parcel = ref<Parcel>();
        const refund = ref(0);
        const shippingMethods = ref<ShippingMethod[]>();
        const shippingMethod = ref<ShippingMethod>();

        watch(
            () => orderController.order,
            () => {
                parcel.value = orderController.estimateParcel();
            },
            { immediate: true, deep: true },
        );

        function product(item: CartItem): Product | undefined {
            return products?.find((product) => product.id == item.productId)?.data;
        }

        function download(item: ImageData): void {
            window.open(item.original, "download");
        }

        function removeItem(item: CartItem): void {
            if (confirm("Are you sure you want to delete this?")) {
                return orderController.removeItem(item);
            }
        }

        function getShippingMethods(): Promise<void> {
            return orderController.getShippingMethods(parcel.value).then((methods) => {
                shippingMethods.value = methods;
            });
        }

        function buyShippingLabel(): Promise<void> {
            if (shippingMethod.value) {
                return orderController.buyShippingLabel(shippingMethod.value);
            }
            return Promise.reject();
        }

        function printShippingLabel(): void {
            window.open(orderController.order.data?.shipping.label?.label, "_blank");
        }

        function trackPackage(): void {
            window.open(orderController.order.data?.shipping.label?.trackingUrl, "_blank");
        }

        function sendInvoice(): Promise<void> {
            return orderController.sendInvoice();
        }

        function processPayment(): Promise<void> {
            return orderController.processPayment();
        }

        function issueRefund(): Promise<void> {
            return orderController.issueRefund(refund.value);
        }

        function cancelOrder(): Promise<void> {
            if (confirm("Are you sure you want to cancel this order?")) {
                return orderController.cancel();
            }

            return Promise.reject();
        }

        function deleteOrder(): Promise<void> {
            if (confirm("Are you sure you want to delete this order?")) {
                return orderController.delete().then(() => {
                    router.push("/admin/orders");
                });
            }

            return Promise.reject();
        }

        return {
            orderId,
            order: orderController.order,
            orderController,
            parcel,
            refund,
            shippingMethods,
            shippingMethod,
            download,
            product,
            removeItem,
            sendInvoice,
            getShippingMethods,
            buyShippingLabel,
            printShippingLabel,
            trackPackage,
            processPayment,
            issueRefund,
            cancelOrder,
            deleteOrder,
        };
    },
};

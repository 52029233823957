<template>
    <c-panel>
        <c-grid v-if="order?.data" class="spacing-xl-all">
            <c-grid-item class="width-100-sm width-50-md width-55-lg width-65-xl">
                <div class="mb-5 pb-5" v-for="(item, index) in order.data.cart" :key="index">
                    <c-grid>
                        <c-grid-item class="width-40-all width-100-lg print-hide">
                            <c-card>
                                <c-image
                                    :image="item.image"
                                    v-if="item.image && product(item)?.category == 'Custom Print'"
                                    :zoom="true"
                                />
                            </c-card>
                            <c-card v-if="product(item)?.category == 'Gallery Print'">
                                <c-image :image="product(item)?.images[0]" :zoom="true" />
                            </c-card>
                        </c-grid-item>
                        <c-grid-item class="width-grow-all">
                            <h2>{{ product(item)?.name }}</h2>
                            <p class="p-0 m-0" v-if="item.size">
                                <span class="text-bold">Size: </span>
                                <span>{{ item.size.width }}&times;{{ item.size.height }}</span>
                            </p>
                            <p class="p-0 m-0" v-for="(option, selection) in item.selections" :key="selection">
                                <span class="text-bold">{{ selection }}: </span>
                                <span>{{ option }}</span>
                            </p>
                            <c-grid class="f-align-end">
                                <c-grid-item>
                                    <c-numberbox class="mt-3" v-model="item.quantity" />
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button-group class="mt-3">
                                        <c-button v-if="item.image" @click="download(item.image)">
                                            <template #icon>
                                                <i class="fas fa-download"></i>
                                            </template>
                                        </c-button>
                                        <c-button
                                            v-else-if="product(item).images[0]"
                                            @click="download(product(item).images[0])"
                                        >
                                            <template #icon>
                                                <i class="fas fa-download"></i>
                                            </template>
                                        </c-button>
                                        <c-button class="red" @click="removeItem(item)">
                                            <template #icon>
                                                <i class="fas fa-trash"></i>
                                            </template>
                                        </c-button>
                                    </c-button-group>
                                </c-grid-item>
                            </c-grid>
                        </c-grid-item>
                        <c-grid-item class="width-shrink-all">
                            <h2>${{ item.price.toFixed(0) }}</h2>
                        </c-grid-item>
                    </c-grid>
                </div>
            </c-grid-item>

            <c-grid-item class="width-100-sm width-50-md width-45-lg width-35-xl">
                <c-grid class="widths-100-all">
                    <c-grid-item>
                        <c-box class="p-4">
                            <h1 class="mb-5">Summary</h1>
                            <c-grid class="widths-100-all spacing-lg-all">
                                <c-grid-item>
                                    <c-order-invoice :orderId="order.id" />
                                </c-grid-item>
                            </c-grid>
                        </c-box>
                    </c-grid-item>
                    <c-grid-item v-if="order.data.status != 'Cancelled'">
                        <c-box v-if="parcel" class="p-3">
                            <h1 class="mb-5">Shipping</h1>
                            <c-grid class="pb-3" v-if="Object.keys(order.data.shipping.address).length != 0">
                                <c-grid-item class="width-100-all">
                                    <p v-if="order.data.shipping.address">
                                        {{ order.data.shipping.address.name }}<br />
                                        {{ order.data.shipping.address.street1 }}<br />
                                        <template v-if="order.data.shipping.address.street2">
                                            {{ order.data.shipping.address.street2 }}<br />
                                        </template>
                                        {{ order.data.shipping.address.city }},
                                        {{ order.data.shipping.address.state }}
                                        {{ order.data.shipping.address.zip }}<br />
                                        {{ order.data.contact.email }}
                                    </p>
                                    <p>Method: {{ order.data.shipping.method.service }}</p>
                                </c-grid-item>
                            </c-grid>
                            <c-grid
                                v-if="
                                    !shippingMethods &&
                                    !order.data.shipping.label &&
                                    !order.data.shipping.method.service?.includes('Local')
                                "
                            >
                                <c-grid-item class="width-33-all">
                                    <c-textbox label="Width" v-model="parcel.width" :suffix="parcel.distance_unit" />
                                </c-grid-item>
                                <c-grid-item class="width-33-all">
                                    <c-textbox label="Height" v-model="parcel.height" :suffix="parcel.distance_unit" />
                                </c-grid-item>
                                <c-grid-item class="width-33-all">
                                    <c-textbox label="Length" v-model="parcel.length" :suffix="parcel.distance_unit" />
                                </c-grid-item>
                                <c-grid-item class="width-grow-all">
                                    <c-textbox label="Weight" v-model="parcel.weight" :suffix="parcel.mass_unit" />
                                </c-grid-item>
                                <c-grid-item class="flex f-align-end">
                                    <c-button @click="getShippingMethods()">Get Rates</c-button>
                                </c-grid-item>
                            </c-grid>
                            <c-grid class="widths-100-all" v-else-if="shippingMethods && !order.data.shipping.label">
                                <c-grid-item v-for="(method, index) in shippingMethods" :key="index">
                                    <c-radio :value="method" v-model="shippingMethod">
                                        <c-grid class="f-align-center" style="width: 100%">
                                            <c-grid-item class="width-grow-all">
                                                <h3 class="m-0 p-0">{{ method.service }}</h3>
                                            </c-grid-item>
                                            <c-grid-item v-if="(method.price ?? 0) > 0">
                                                <h3 class="m-0 p-0">${{ Math.round(method.price ?? 0) }}</h3>
                                            </c-grid-item>
                                            <c-grid-item v-else>
                                                <h3 class="m-0 p-0">Free</h3>
                                            </c-grid-item>
                                        </c-grid>
                                    </c-radio>
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button class="large" @click="buyShippingLabel()">Buy Label</c-button>
                                </c-grid-item>
                            </c-grid>
                            <c-grid class="widths-100-all spacing-sm-all" v-else-if="order.data.shipping.label">
                                <c-grid-item>
                                    <c-button class="large" @click="printShippingLabel()">Print Label</c-button>
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button class="large" @click="trackPackage()">Track Package</c-button>
                                </c-grid-item>
                            </c-grid>
                        </c-box>
                    </c-grid-item>
                    <c-grid-item>
                        <c-box v-if="parcel" class="p-3">
                            <h1 class="mb-5">Payment</h1>
                            <c-grid class="pb-5" v-if="order.data.status == 'Completed'">
                                <c-grid-item class="width-grow-all">
                                    <c-textbox label="Refund" type="number" prefix="$" v-model="refund" />
                                </c-grid-item>
                                <c-grid-item class="flex f-align-end">
                                    <c-button @click="issueRefund()">Issue</c-button>
                                </c-grid-item>
                            </c-grid>
                            <c-grid class="widths-100-all spacing-sm-all">
                                <c-grid-item v-if="order.data.status != 'Cancelled'">
                                    <c-button
                                        class="large"
                                        v-if="order.data.status != 'Completed'"
                                        @click="processPayment()"
                                    >
                                        Process Payment
                                    </c-button>
                                    <c-button class="large" v-else :disabled="true">
                                        <template #icon>
                                            <i class="fas fa-check"></i>
                                        </template>
                                        Payment Completed
                                    </c-button>
                                </c-grid-item>
                                <c-grid-item v-if="order.data.status != 'Cancelled'">
                                    <c-button class="large" @click="sendInvoice()">Resend Invoice</c-button>
                                </c-grid-item>
                                <c-grid-item v-if="order.data.status != 'Completed'">
                                    <c-button
                                        v-if="order.data.status != 'Cancelled'"
                                        class="large red"
                                        @click="cancelOrder()"
                                    >
                                        Cancel Order
                                    </c-button>
                                    <c-button
                                        v-else-if="order.data.status == 'Cancelled'"
                                        class="large red"
                                        @click="cancelOrder()"
                                        :disabled="true"
                                    >
                                        Order Cancelled
                                    </c-button>
                                </c-grid-item>
                                <c-grid-item>
                                    <c-button class="large red" @click="deleteOrder()">Delete Order</c-button>
                                </c-grid-item>
                            </c-grid>
                        </c-box>
                    </c-grid-item>
                </c-grid>
            </c-grid-item>
        </c-grid>
    </c-panel>
</template>

<script lang="ts">
import { inject, reactive, watch, ref } from "vue";
import { Database } from "vuebase";
import { CartItem, Parcel, Product, ShippingMethod } from "../main";
import OrderController from "@/controllers/order-controller";
import { useRoute, useRouter } from "vue-router";
import { ImageData } from "coho-ui";

export default {
    name: "Order",
    setup() {
        const route = useRoute();
        const router = useRouter();
        const orderId = (route.params.id as string) || undefined;
        const database = inject(Database.InjectionKey);
        const products = database?.collection<Product>("products").documents();
        const orderController = reactive(new OrderController(orderId));
        const parcel = ref<Parcel>();
        const refund = ref(0);
        const shippingMethods = ref<ShippingMethod[]>();
        const shippingMethod = ref<ShippingMethod>();

        watch(
            () => orderController.order,
            () => {
                parcel.value = orderController.estimateParcel();
            },
            { immediate: true, deep: true },
        );

        function product(item: CartItem): Product | undefined {
            return products?.find((product) => product.id == item.productId)?.data;
        }

        function download(item: ImageData): void {
            window.open(item.original, "download");
        }

        function removeItem(item: CartItem): void {
            if (confirm("Are you sure you want to delete this?")) {
                return orderController.removeItem(item);
            }
        }

        function getShippingMethods(): Promise<void> {
            return orderController.getShippingMethods(parcel.value).then((methods) => {
                shippingMethods.value = methods;
            });
        }

        function buyShippingLabel(): Promise<void> {
            if (shippingMethod.value) {
                return orderController.buyShippingLabel(shippingMethod.value);
            }
            return Promise.reject();
        }

        function printShippingLabel(): void {
            window.open(orderController.order.data?.shipping.label?.label, "_blank");
        }

        function trackPackage(): void {
            window.open(orderController.order.data?.shipping.label?.trackingUrl, "_blank");
        }

        function sendInvoice(): Promise<void> {
            return orderController.sendInvoice();
        }

        function processPayment(): Promise<void> {
            return orderController.processPayment();
        }

        function issueRefund(): Promise<void> {
            return orderController.issueRefund(refund.value);
        }

        function cancelOrder(): Promise<void> {
            if (confirm("Are you sure you want to cancel this order?")) {
                return orderController.cancel();
            }

            return Promise.reject();
        }

        function deleteOrder(): Promise<void> {
            if (confirm("Are you sure you want to delete this order?")) {
                return orderController.delete().then(() => {
                    router.push("/admin/orders");
                });
            }

            return Promise.reject();
        }

        return {
            orderId,
            order: orderController.order,
            orderController,
            parcel,
            refund,
            shippingMethods,
            shippingMethod,
            download,
            product,
            removeItem,
            sendInvoice,
            getShippingMethods,
            buyShippingLabel,
            printShippingLabel,
            trackPackage,
            processPayment,
            issueRefund,
            cancelOrder,
            deleteOrder,
        };
    },
};
</script>
